<template>
    <div v-if="terms && rsettings">
        <span v-for="(term,i) in terms.filter((item)=>{return item.parameter_id == rsettings[0].form.parameter_id})" :key="i">
            Term: <strong>{{term.term}} Term</strong><br>
            Session: <strong>{{term.session.session}}</strong><br>
        </span>
    </div>
</template>

<script>
export default {
    name: 'SelectedTerm',
    props:[
        'terms',
        'rsettings',
    ],
}
</script>
<template>
    <v-row v-if="rsettings[0].settings.cummulative">
        <v-col cols="12">
            <table class="table mnr" style="z-index:2" v-if="record && rsettings">
                <thead>
                    <tr class="headrow">
                        <th class="text-left">SUBJECTS</th>
                        <score-header :header="'1st CA ('+rsettings[0].form.ca1_maxscore+')'"/>
                        <score-header v-if="school.kinds_of_ca > 1" :header="'2nd CA ('+rsettings[0].form.ca2_maxscore+')'"/>
                        <score-header v-if="school.kinds_of_ca > 2" :header="'3rd CA ('+rsettings[0].form.ca3_maxscore+')'"/>
                        <score-header v-if="school.kinds_of_ca > 3" :header="'4th CA ('+rsettings[0].form.ca4_maxscore+')'"/>
                        <score-header :header="'EXAM ('+rsettings[0].form.exam_maxscore+')'"/>
                        <score-header :header="'TOTAL (100)'"/>
                        <score-header :header="'SUBJECT POSITION'"/>
                        <score-header :header="'1ST TERM B/F'"/>
                        <score-header :header="'2ND TERM B/F'"/>
                        <score-header :header="'3RD TERM '"/>
                        <score-header :header="'CUMM. AVERAGE'"/>
                        <score-header v-if="rsettings[0].settings.sgrading" :header="'GRADE'"/>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="score in record.scores"
                    :key="score.result_id"
                    >
                    <score-data class="text-capitalize" :data="score.subject.subject"/>
                    <score-data class="text-center" :data="score.this_term_scores.ca1"/>
                    <score-data v-if="school.kinds_of_ca > 1" class="text-center" :data="score.this_term_scores.ca2"/>
                    <score-data v-if="school.kinds_of_ca > 2" class="text-center" :data="score.this_term_scores.ca3"/>
                    <score-data v-if="school.kinds_of_ca > 3" class="text-center" :data="score.this_term_scores.ca4"/>
                    <score-data class="text-center" :data="score.this_term_scores.exam"/>
                    <score-data class="text-center" :data="score.this_term_scores.total"/>
                    <score-data class="text-center" :data="score.this_term_scores.computed_subject_position"/>
                    <score-data class="text-center" :data="score.three_term_scores.total.t1"/>
                    <score-data class="text-center" :data="score.three_term_scores.total.t2"/>
                    <score-data class="text-center" :data="score.three_term_scores.total.t3"/>
                    <score-data class="text-center font-weight-bold" :data="score.three_term_scores.avg"/>
                    <score-data v-if="rsettings[0].settings.sgrading" class="text-center font-weight-bold" :data="score.three_term_scores.grade"/>
                    </tr>
                </tbody>
            </table>
        </v-col>
    </v-row>
</template>

<script>
import ScoreData from './ScoreData.vue'
import ScoreHeader from './ScoreHeader.vue'
export default {
  components: { ScoreData, ScoreHeader },
    name: 'ThreeTermSubjectScores',
    props:[
        'record',
        'rsettings',
        'school'
    ],
}
</script>

<style>
.mnr{
    border-collapse: collapse;
    width: 100%;
}
.mnr tr > td, .mnr tr > th{
    border: 2px solid #333 !important; 
    padding: 0 4px 0 4px;
}
.mnr tbody tr td{
    font-size: 12px;
}
.headrow{height: 150px;}
.headrow th.vtext{height:100px; vertical-align: bottom; padding-left: 0;}
.headrow th.vtext > div{
    transform: rotate(270deg);
    transform-origin: 0% 0%;
    width: 1px;
}
tr th.vtext span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 130px;
}
</style>
<template>
    <div v-if="terms && rsettings">
        <span v-if="rsettings[0].settings.pinfo">
            <span class="" v-for="(term,i) in terms.filter((item)=>{return item.parameter_id == rsettings[0].form.parameter_id})" :key="i">
                <small><strong>INFO TO PARENTS:</strong> <span class="caption">{{term.generalupdate}}</span></small><br>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'ParentsInfo',
    props:[
        'terms',
        'rsettings',
    ],
}
</script>
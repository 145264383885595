<template>
    <default-template v-if="school.plan =='Free' || rsettings[0].settings.template == 1 || rsettings[0].settings.template == 6" :records="records" :school="school" :rsettings="rsettings" :terms="terms" :gradings="gradings"/>

    <t-2 v-else-if="rsettings[0].settings.template == 2" :records="records" :school="school" :rsettings="rsettings" :terms="terms" :gradings="gradings"/>

    <t-3 v-else-if="rsettings[0].settings.template == 3" :records="records" :school="school" :rsettings="rsettings" :terms="terms" :gradings="gradings"/>

    <t-4 v-else-if="rsettings[0].settings.template == 4 || rsettings[0].settings.template == 5" :records="records" :school="school" :rsettings="rsettings" :terms="terms" :gradings="gradings"/>
</template>

<script>
import T2 from './Custom/T2.vue'
import T3 from './Custom/T3.vue'
import T4 from './Custom/T4.vue'
import DefaultTemplate from './DefaultTemplate.vue'
export default {
  components: { DefaultTemplate, T2, T3, T4 },
    name: 'CustomTemplateChanger',
    props:[
        'school',
        'records',
        'rsettings',
        'terms',
        'gradings',
    ]
}
</script>



<template>
    <v-row>
        <v-col cols="12">
            <div class="hborder print-zone d-none d-sm-block d-print-block" v-for="(record, i) in filteredRecords" :key="i">
                <v-row class="mt-1 px-1">
                    <v-col cols="2" class="rborder"> 
                        <school-logo :school="school"/>
                    </v-col>
                    <v-col cols="8" class="rborder text-center">
                        <school-name :school="school" :rsettings="rsettings" :styler="'indigo--text font-weight-bolder'"/>
                        <school-address :school="school" :rsettings="rsettings"/>
                        <h4 class="mt-2">&laquo;&laquo; STUDENT'S ACADEMIC REPORT CARD&raquo;&raquo;</h4>
                    </v-col>
                    <v-col cols="2" class="">
                        <student-passport :school="school" :rsettings="rsettings" :record="record"/><br>
                        <student-class-position :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="px-3">
                    <v-col cols="12" class="tbborder text-center indigo lighten-4 black--text">
                        <student-info :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-1">
                    <v-col cols="4" class="rbborder"> 
                        <selected-term :terms="terms" :rsettings="rsettings"/>
                        <next-term-resumption :terms="terms" :rsettings="rsettings"/>
                    </v-col>
                    <v-col cols="4" class="rbborder">                    
                        <student-class :record="record"/>
                        <class-size :rsettings="rsettings" :record="record"/>
                        <class-teacher-name :rsettings="rsettings" :record="record"/>
                    </v-col>
                    <v-col cols="4" class="bborder">
                        <attendance :terms="terms" :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-1">
                    <v-col cols="9" class="bborder"> 
                        <water-marks :school="school" :rsettings="rsettings"/>
                        <one-term-subject-scores :rsettings="rsettings" :record="record" :school="school" :styler="'indigo--text font-weight-bolder'"/>
                        <three-term-subject-scores :rsettings="rsettings" :record="record" :school="school"/>
                    </v-col>
                    <v-col cols="3" class="bborder">  
                        <general-motor-skills :rsettings="rsettings" :record="record" :styler="'indigo--text'"/>                      
                        <grading-system :gradings="gradings" :rsettings="rsettings" :styler="'indigo--text'"/>
                    </v-col>
                </v-row>
                <v-row class="mt-1 px-1">
                    <v-col cols="9" class="bborder"> 
                        <score-computations :rsettings="rsettings" :record="record" :styler="'indigo--text'"/>
                    </v-col>  
                    <v-col cols="3" class="lbborder"> 
                        <signature-and-stamp :school="school" :styler="'indigo--text'"/>
                    </v-col>                    
                </v-row>
                <v-row class="mt-2 px-1">
                    <v-col cols="12"> 
                        <remarks :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="mt-1 px-1">
                    <v-col cols="12" class="tborder text-center"> 
                        <parents-info :terms="terms" :rsettings="rsettings"/>
                        <small v-if="school.plan == 'Free'">Printed from MYSCH.NG</small>
                    </v-col>
                </v-row>
            </div>
            <div class="d-flex d-sm-none d-print-none">
                Click the Print button to see the results preview
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Attendance from './Parts/Attendance.vue'
import ClassSize from './Parts/ClassSize.vue'
import ClassTeacherName from './Parts/ClassTeacherName.vue'
import GeneralMotorSkills from './Parts/GeneralMotorSkills.vue'
import GradingSystem from './Parts/GradingSystem.vue'
import NextTermResumption from './Parts/NextTermResumption.vue'
import OneTermSubjectScores from './Parts/OneTermSubjectScores.vue'
import ParentsInfo from './Parts/ParentsInfo.vue'
import Remarks from './Parts/Remarks.vue'
import SchoolAddress from './Parts/SchoolAddress.vue'
import SchoolLogo from './Parts/SchoolLogo.vue'
import SchoolName from './Parts/SchoolName.vue'
import ScoreComputations from './Parts/ScoreComputations.vue'
import SelectedTerm from './Parts/SelectedTerm.vue'
import SignatureAndStamp from './Parts/SignatureAndStamp.vue'
import StudentClass from './Parts/StudentClass.vue'
import StudentClassPosition from './Parts/StudentClassPosition.vue'
import StudentInfo from './Parts/StudentInfo.vue'
import StudentPassport from './Parts/StudentPassport.vue'
import ThreeTermSubjectScores from './Parts/ThreeTermSubjectScores.vue'
import WaterMarks from './Parts/WaterMarks.vue'
export default {
  components: { SchoolName, SchoolAddress, SchoolLogo, StudentPassport, StudentClassPosition, StudentInfo, StudentClass, SelectedTerm, ClassSize, ClassTeacherName, NextTermResumption, Attendance, GradingSystem, Remarks, SignatureAndStamp, ParentsInfo, ScoreComputations, WaterMarks, OneTermSubjectScores, GeneralMotorSkills, ThreeTermSubjectScores },
    name: 'DefaultTemplate',
    props:[
        'records', 
        'school',
        'rsettings',
        'terms',
        'gradings',
    ],
    data () {
        return {
        }
    },
    computed:{
        filteredRecords(){
            if (this.rsettings[0].form && this.rsettings[0].form.scs_id){

                return this.records.filter((item)=>{return item.scs_id == this.rsettings[0].form.scs_id})
            }
            return this.records
        }
    },
    methods:{
    }
}
</script>

<style scoped>
.print-zone{
    margin-bottom: 100px;
}

@media print{
    .print-zone{
        page-break-after: always !important;
        position: relative !important;
        font-weight: 500;
        margin-bottom: 0 !important;
    }
}

@media only screen and (max-width: 650px) {
    .print-zone{
        zoom: 0.7;
        -moz-transform: scale(0.7);
    }
}
</style>
<template>
  <div v-if="school">
      <h2 :class="'text-uppercase '+styler">{{school.name}}</h2>
      <small v-if="rsettings && rsettings[0].settings.motto">Motto: {{school.motto}}</small>
  </div>
</template>

<script>
export default {
    name: 'SchoolName',
    props:[
        'school',
        'rsettings',
        'styler'
    ],
}
</script>
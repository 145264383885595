<template>
    <th class="vtext caption font-weight-bold"><div><span>{{header}}</span></div></th>
</template>

<script>
export default {
    name: 'ScoreHeader',
    props:[
        'header'
    ],
}
</script>

<style scoped>

</style>
<template>
  <div v-if="school && rsettings" style="position:relative">
    <div class="col-4 offset-2" style="position:absolute; z-index:1; opacity:0.1">
        <img v-if="rsettings[0].settings.watermark" :src="school.logo" height="400px">
    </div>
  </div>
</template>

<script>
export default {
    name: 'WaterMarks',
    props:[
        'school',
        'rsettings'
    ],
}
</script>
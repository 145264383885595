<template>
    <div v-if="record">
        <h4 :class="'headline text-uppercase '+styler">
            <strong>{{record.student.fullname}}</strong>
        </h4>
        <span v-if="rsettings[0].settings.gender">Gender: <strong>{{record.student.gender_letter}}</strong></span>
        <span v-if="rsettings[0].settings.regnum">  &nbsp;| &nbsp; Admission Number: <strong>{{record.student.student_regno}}</strong></span>
        <span v-if="rsettings[0].settings.age">  &nbsp;| &nbsp; Age: <strong>{{record.student.age}}</strong></span>
    </div>
</template>

<script>
export default {
    name: 'StudentInfo',
    props:[
        'record',
        'rsettings',
        'styler'
    ],
}
</script>
<template>
    <div v-if="record">
        <span v-if="rsettings[0].settings.cteacher">Class Teacher: <strong>{{record.class_teacher_name}}</strong></span>
    </div>
</template>

<script>
export default {
    name: 'ClassTeacherName',
    props:[
        'record',
        'rsettings',
    ],
}
</script>
<template>
    <v-row>
        <v-col cols="12">
            <h5 :class="styler">SUMMARY</h5>
            <table class="table mnr" v-if="record && rsettings">
            <tbody>
                <tr>
                <td>TOTAL SCORE:</td>
                <td><strong>{{ record.computations.total_score }} <span v-if="rsettings[0].settings.ptss">/ {{record.computations.total_subjects_recorded * 100}}</span></strong></td>
                
                <td>AVG. SCORE:</td>
                <td><strong>{{ record.computations.average_score }} %</strong></td>
                
                <td>GRADE:</td>
                <td><strong>{{ record.computations.overall_grade }}</strong></td>
                </tr>
            </tbody>
            </table>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ScoreComputations',
    props:[
        'rsettings',
        'record',
        'styler'
    ],
}
</script>
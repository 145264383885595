<template>
    <div v-if="record">
        <h2 v-if="rsettings[0].settings.cposition">{{record.computations.student_position}} <v-icon>mdi-medal</v-icon> <slot/>
        </h2>
    </div>
</template>

<script>
export default {
    name: 'StudentClassPosition',
    props:[
        'record',
        'rsettings',
    ],
}
</script>
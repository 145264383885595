<template>
  <div v-if="school">
        <small v-if="rsettings[0].settings.address">
          TEL: <strong>{{school.phone}}</strong><br>EMAIL: <strong>{{school.email}}</strong>
        </small>
  </div>
</template>

<script>
export default {
    name: 'SchoolEmailPhone',
    props:[
        'school',
        'rsettings',
    ],
}
</script>
<template>
  <div v-if="school">
      <img :src="school.logo" :height="height ? height : '100px'">
  </div>
</template>

<script>
export default {
    name: 'SchoolLogo',
    props:[
        'school',
        'height'
    ],
}
</script>
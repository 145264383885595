<template>
    <v-row>
        <v-col cols="12">
            <div class="hborder print-zone d-none d-sm-block d-print-block" v-for="(record, i) in filteredRecords" :key="i">
                <v-row class="mt-1 px-2">
                    <v-col cols="12" class="pt-3 text-center light-r-border light-b-border">
                        <school-name :styler="'text-h4 font-weight-bold primary--text'" :school="school" :rsettings="rsettings"/>
                    </v-col>                    
                    <v-col cols="5" class="">
                        <school-address-2 class="pt-3" :school="school" :rsettings="rsettings"/>
                    </v-col>
                    <v-col cols="2" class="text-center"> 
                        <school-logo :school="school" :height="'50px'"/>
                    </v-col>
                    <v-col cols="5" class="">
                        <school-email-phone class="pt-3" :school="school" :rsettings="rsettings"/>
                    </v-col>
                </v-row>
                <v-row class="px-3 mb-1 tbcolor">
                    <v-col cols="10" class="offset-1 text-center py-1" style="background:#f5f5f5">  
                        <v-row>
                            <v-col cols="12" md="12" class="pt-1">
                                <student-info :rsettings="rsettings" :record="record"/>
                            </v-col>
                        </v-row>                      
                    </v-col>
                </v-row>
                <v-row class="px-1 text-uppercase">
                    <v-col cols="4" class="light-t-border light-r-border light-b-border pl-3"> 
                        <selected-term :terms="terms" :rsettings="rsettings"/>              
                        <next-term-resumption :terms="terms" :rsettings="rsettings"/>
                    </v-col>
                    <v-col cols="4" class="light-t-border light-r-border light-b-border pl-2">                    
                        <student-class :record="record"/>
                        <class-size :rsettings="rsettings" :record="record"/>         
                        <class-teacher-name :rsettings="rsettings" :record="record"/> 
                    </v-col>
                    <v-col cols="4" class="light-t-border light-l-border light-b-border pl-2">
                        <attendance :terms="terms" :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="px-3 primary">
                    <v-col cols="12" class="text-center py-2">
                        <h2 class="my-1 white--text">&laquo;&laquo; ACADEMIC PERFORMANCE REPORT &raquo;&raquo;</h2>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-2">
                    <v-col class="pr-2 mb-1"> 
                        <water-marks :school="school" :rsettings="rsettings"/>
                        <one-term-subject-scores :rsettings="rsettings" :record="record" :school="school"/>
                        <three-term-subject-scores :rsettings="rsettings" :record="record" :school="school"/>                        
                        <score-computations :rsettings="rsettings" :record="record"/>
                        <student-class-position v-if="0==1" :rsettings="rsettings" :record="record"> Position</student-class-position><br>
                        <remarks :rsettings="rsettings" :record="record"/>
                    </v-col>
                    <v-col cols="3" v-if="rsettings[0].settings.ktg || rsettings[0].settings.psymotor">  
                        <general-motor-skills :rsettings="rsettings" :record="record"/>                      
                        <grading-system :gradings="gradings" :rsettings="rsettings"/>
                        <signature-and-stamp :school="school"/>
                    </v-col>
                </v-row>
                <v-row class="mt-1 px-1 tborder">
                    <v-col cols="12"> 
                        <parents-info :terms="terms" :rsettings="rsettings"/>
                    </v-col>
                </v-row>
            </div>
            <div class="d-flex d-sm-none d-print-none">
                Click the Print button to see the results preview
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Attendance from '../Parts/Attendance.vue'
import ClassSize from '../Parts/ClassSize.vue'
import ClassTeacherName from '../Parts/ClassTeacherName.vue'
import GeneralMotorSkills from '../Parts/GeneralMotorSkills.vue'
import GradingSystem from '../Parts/GradingSystem.vue'
import NextTermResumption from '../Parts/NextTermResumption.vue'
import OneTermSubjectScores from '../Parts/OneTermSubjectScores.vue'
import ParentsInfo from '../Parts/ParentsInfo.vue'
import Remarks from '../Parts/Remarks.vue'
import SchoolAddress2 from '../Parts/SchoolAddress2.vue'
import SchoolEmailPhone from '../Parts/SchoolEmailPhone.vue'
import SchoolLogo from '../Parts/SchoolLogo.vue'
import SchoolName from '../Parts/SchoolName.vue'
import ScoreComputations from '../Parts/ScoreComputations.vue'
import SelectedTerm from '../Parts/SelectedTerm.vue'
import SignatureAndStamp from '../Parts/SignatureAndStamp.vue'
import StudentClass from '../Parts/StudentClass.vue'
import StudentClassPosition from '../Parts/StudentClassPosition.vue'
import StudentInfo from '../Parts/StudentInfo.vue'
import ThreeTermSubjectScores from '../Parts/ThreeTermSubjectScores.vue'
import WaterMarks from '../Parts/WaterMarks.vue'
export default {
  components: { SchoolName, SchoolLogo, StudentClassPosition, StudentInfo, StudentClass, SelectedTerm, ClassSize, ClassTeacherName, NextTermResumption, Attendance, GradingSystem, Remarks, ParentsInfo,  WaterMarks, OneTermSubjectScores, GeneralMotorSkills, ThreeTermSubjectScores, SchoolEmailPhone, SchoolAddress2, ScoreComputations, SignatureAndStamp },
    name: 'T3',
    props:[
        'records', 
        'school',
        'rsettings',
        'terms',
        'gradings',
    ],
    data () {
        return {
        }
    },
    computed:{
        filteredRecords(){
            if (this.rsettings[0].form && this.rsettings[0].form.scs_id){

                return this.records.filter((item)=>{return item.scs_id == this.rsettings[0].form.scs_id})
            }
            return this.records
        }
    },
    methods:{
    }
}
</script>

<style scoped>
@media print{
    .print-zone{
        page-break-after: always !important;
        position: relative !important;
        font-weight: 500;
        margin-bottom: 0 !important;
    }
}
.print-zone{
    margin-bottom: 100px;
}
.light-r-border{
    border-right: 1px solid #ccc;
}
.light-l-border{
    border-left: 1px solid #ccc;
}
.light-t-border{
    border-top: 1px solid #ccc;
}
.light-b-border{
    border-bottom: 1px solid #ccc;
}
.bold-tb-border{
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}
.bold-r-border{
    border-right: 2px solid #000;
}
.tbcolor{
    border-top: 3px solid blue;
}

@media only screen and (max-width: 650px) {
    .print-zone{
        zoom: 0.7;
        -moz-transform: scale(0.7);
    }
}
</style>
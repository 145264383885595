<template>
    <td>{{data}}</td>
</template>

<script>
export default {
    name: 'ScoreData',
    props:[
        'data'
    ],
}
</script>

<style scoped>

</style>
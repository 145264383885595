<template>
  <div v-if="school">
      <small v-if="rsettings[0].settings.address">Address: {{school.address}}</small>
  </div>
</template>

<script>
export default {
    name: 'SchoolAddress',
    props:[
        'school',
        'rsettings',
    ],
}
</script>
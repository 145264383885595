<template>
  <div v-if="record">
      <img :src="record.student.passport" height="70px" v-if="rsettings[0].settings.photo">
  </div>
</template>

<script>
export default {
    name: 'StudentPassport',
    props:[
        'school',
        'rsettings',
        'record',
    ],
}
</script>
<template>
<v-container>
    <v-row class="noprint" v-if="!studentsheetForm && !broadsheetForm">
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    RESULT SHEETS
                </v-card-title>
                <v-card-subtitle>
                    View and Print Results
                </v-card-subtitle>
                <v-card-text v-if="fetchingContent">
                    <info-loader-2/>
                </v-card-text>
                <v-card-text v-else>
                    <v-btn text color="info" @click="showBroadsheetForm" v-if="authToken">Broadsheet &raquo;</v-btn> 
                    <v-btn text color="primary" @click="showStudentsheetForm">Student Report Sheets &raquo;</v-btn>
                </v-card-text>
                <v-card-text v-if="authToken">
                    <v-alert
                        type="info"
                        dense
                        border="left"
                        text
                        color="orange"
                        >
                        <marquee>
                            <span v-for="(info,i) in infos" :key="i" class="black--text mr-5">
                            {{info.content}} | &nbsp;
                            </span>
                        </marquee>
                    </v-alert>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
            <v-card-text>
                <student-result-sheet-modal v-if="studentsheetForm && reRenderComponent" :accessToken="accessToken" :authToken="authToken" :school="school" :classes="classes" :terms="terms" :gradings="gradings"/>
                
                <broad-sheet-modal v-if="broadsheetForm && reRenderComponent" :accessToken="accessToken" :authToken="authToken" :school="school" :classes="classes" :terms="terms" :gradings="gradings"/>
            </v-card-text>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import HeaderVue from '../../components/Header.vue'
import StudentResultSheetModal from '../../components/sheets/StudentResultSheetModal.vue';
import BroadSheetModal from '../../components/sheets/BroadSheetModal.vue';
import InfoLoader2 from '../../components/InfoLoader2.vue';
  export default {
    name: 'ResultSheets',
    components: {
        HeaderVue,
        StudentResultSheetModal,
        BroadSheetModal,
        InfoLoader2
    },
    mounted() {
       this.fetchTerms()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        accessToken:sessionStorage.getItem('accessToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : {},
        broadsheetForm: false,
        studentsheetForm: false,
        reRenderComponent: true,
        classes:[],
        terms:[],
        gradings:[],
        infos:[
            {content: 'Be sure to have recorded scores before proceeding to view result sheets.'},
            {content: 'If you get a Result not found message means that there are no results for that class for that term you selected.'},
            {content: 'If results preview is empty, then that student does not have results for the class and term.'},
        ],
        fetchingContent:true
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ],
        link: [
            { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Lato:300,400,500,700' }
        ],
    },
    methods: {
        showBroadsheetForm () {
            this.broadsheetForm = !this.broadsheetForm
            this.studentsheetForm = false
        },
        showStudentsheetForm () {
            this.broadsheetForm = false
            this.studentsheetForm = !this.studentsheetForm
        },
        fetchTerms () {
            axios.get(process.env.VUE_APP_BASEURL+'/parameters?school_id='+this.school.school_id,
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'access_token': this.accessToken,
                    }
            })
            .then((response) => {
                this.terms =  response.data.data 
                if (this.terms.length == 0) {
                    this.$toast.info('You will need to set a session/term first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('terms')
                }        
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                this.fetchClasses()
                this.fetchGradings()
            });
        },
        fetchClasses () {
            axios.get(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.classes =  response.data.data                  
                this.fetchingContent = false;
                 if (this.classes.length == 0) {
                    this.$toast.info('You will need to set classes first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('classes')
                }   
                this.reRenderComponent = false;
                this.$nextTick(() => {
                // Add the component back in
                this.reRenderComponent = true;
                });        
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Internet Network Not Available!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
        fetchGradings () {
            axios.get(process.env.VUE_APP_BASEURL+'/grades?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.gradings =  response.data.data  
                if (this.gradings.length == 0) {
                    this.$toast.info('You will need to set your grading system first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('gradings')
                }           
            })
            .catch((error) => {
                console.log(error.response.status)
            })
            .finally(() => {
            });
        },
    },
  }
</script>
<style>
@media print{
    @page {size: A4 auto}
    .noprint {
        display: none !important;
    }
    .v-sheet.v-stepper:not(.v-sheet--outlined) {
        border: none !important;
        box-shadow: none !important;
    }
    .v-card{
        box-shadow: none !important;
    }
    .container, .row, .v-stepper__content, .v-main{
        margin: 0 !important;
        padding: 0 !important;
    }
    .col{
        padding: 0 0 0 2px !important;
    }
    .print-area{
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        visibility: visible !important;
    } 
}
</style>

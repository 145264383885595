<template>
    <v-row>
        <v-col cols="12">
            <div class="hborder print-zone d-none d-sm-block d-print-block" v-for="(record, i) in filteredRecords" :key="i">
                <v-row class="mt-1 px-2 py-2">
                    <v-col cols="2" class="light-t-border light-r-border light-b-border"> 
                        <school-logo :school="school"/>
                    </v-col>
                    <v-col cols="8" class="pt-3 text-center light-t-border light-r-border light-b-border">
                        <school-name class="text-uppercase" :school="school" :rsettings="rsettings"/>
                        <school-address :school="school" :rsettings="rsettings"/>
                    </v-col>
                    <v-col cols="2" class="light-t-border light-l-border light-b-border">
                        <student-passport :school="school" :rsettings="rsettings" :record="record"/><br>
                        <student-class-position :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="px-3 mb-3">
                    <v-col cols="8" class="offset-2 text-center hborder py-2" style="background:#f5f5f5">
                        <student-info :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-1 text-uppercase">
                    <v-col cols="4" class="light-t-border light-r-border light-b-border pl-3"> 
                        <selected-term :terms="terms" :rsettings="rsettings"/>
                    </v-col>
                    <v-col cols="4" class="light-t-border light-r-border light-b-border pl-2">                    
                        <student-class :record="record"/>
                        <class-size :rsettings="rsettings" :record="record"/>
                        <class-teacher-name :rsettings="rsettings" :record="record"/>
                    </v-col>
                    <v-col cols="4" class="light-t-border light-l-border light-b-border pl-2">
                        <attendance :terms="terms" :rsettings="rsettings" :record="record"/>
                        <next-term-resumption :terms="terms" :rsettings="rsettings"/>
                    </v-col>
                </v-row>
                <v-row class="px-3">
                    <v-col cols="12" class="text-center py-2">
                        <h2 class="my-1">&laquo;&laquo; ACADEMIC PERFORMANCE &raquo;&raquo;</h2>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-2">
                    <v-col class="pr-2 mb-1"> 
                        <water-marks :school="school" :rsettings="rsettings"/>
                        <one-term-subject-scores :rsettings="rsettings" :record="record" :school="school"/>
                        <three-term-subject-scores :rsettings="rsettings" :record="record" :school="school"/>
                        <v-row>
                            <v-col cols="6" class="mt-2">                       
                                <score-computations-b :rsettings="rsettings" :record="record"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="3" v-if="rsettings[0].settings.ktg || rsettings[0].settings.psymotor">  
                        <general-motor-skills :rsettings="rsettings" :record="record"/>                      
                        <grading-system :gradings="gradings" :rsettings="rsettings"/>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-1">
                    <v-col cols="12"> 
                        <remarks :rsettings="rsettings" :record="record"/>
                    </v-col>
                </v-row>
                <v-row class="mt-1 px-1">
                    <v-col cols="12" class="tborder text-center"> 
                        <parents-info :terms="terms" :rsettings="rsettings"/>
                        <small v-if="school.plan == 'Free'">Printed from MYSCH.NG</small>
                    </v-col>
                </v-row>
            </div>
            <div class="d-flex d-sm-none d-print-none">
                Click the Print button to see the results preview
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Attendance from '../Parts/Attendance.vue'
import ClassSize from '../Parts/ClassSize.vue'
import ClassTeacherName from '../Parts/ClassTeacherName.vue'
import GeneralMotorSkills from '../Parts/GeneralMotorSkills.vue'
import GradingSystem from '../Parts/GradingSystem.vue'
import NextTermResumption from '../Parts/NextTermResumption.vue'
import OneTermSubjectScores from '../Parts/OneTermSubjectScores.vue'
import ParentsInfo from '../Parts/ParentsInfo.vue'
import Remarks from '../Parts/Remarks.vue'
import SchoolAddress from '../Parts/SchoolAddress.vue'
import SchoolLogo from '../Parts/SchoolLogo.vue'
import SchoolName from '../Parts/SchoolName.vue'
import ScoreComputationsB from '../Parts/ScoreComputationsB.vue'
import SelectedTerm from '../Parts/SelectedTerm.vue'
import StudentClass from '../Parts/StudentClass.vue'
import StudentClassPosition from '../Parts/StudentClassPosition.vue'
import StudentInfo from '../Parts/StudentInfo.vue'
import StudentPassport from '../Parts/StudentPassport.vue'
import ThreeTermSubjectScores from '../Parts/ThreeTermSubjectScores.vue'
import WaterMarks from '../Parts/WaterMarks.vue'
export default {
  components: { SchoolName, SchoolAddress, SchoolLogo, StudentPassport, StudentClassPosition, StudentInfo, StudentClass, SelectedTerm, ClassSize, ClassTeacherName, NextTermResumption, Attendance, GradingSystem, Remarks, ParentsInfo, ScoreComputationsB, WaterMarks, OneTermSubjectScores, GeneralMotorSkills, ThreeTermSubjectScores },
    name: 'T3',
    props:[
        'records', 
        'school',
        'rsettings',
        'terms',
        'gradings',
    ],
    data () {
        return {
        }
    },
    computed:{
        filteredRecords(){
            if (this.rsettings[0].form && this.rsettings[0].form.scs_id){

                return this.records.filter((item)=>{return item.scs_id == this.rsettings[0].form.scs_id})
            }
            return this.records
        }
    },
    methods:{
    }
}
</script>

<style scoped>
@media print{
    .print-zone{
        page-break-after: always !important;
        position: relative !important;
        font-weight: 500;
        margin-bottom: 0 !important;
    }
}
.print-zone{
    margin-bottom: 100px;
}
.light-r-border{
    border-right: 1px solid #ccc;
}
.light-l-border{
    border-left: 1px solid #ccc;
}
.light-t-border{
    border-top: 1px solid #ccc;
}
.light-b-border{
    border-bottom: 1px solid #ccc;
}
.bold-tb-border{
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}
.bold-r-border{
    border-right: 2px solid #000;
}

@media only screen and (max-width: 650px) {
    .print-zone{
        zoom: 0.7;
        -moz-transform: scale(0.7);
    }
}
</style>
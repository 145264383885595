<template>
  <v-row class="print-area">
      <v-col cols="12">
        <v-card>
            <v-toolbar
            dark
            color="#000"
            class="noprint"
            dense
            >
            <v-btn
                icon
                dark
                @click="reloadPage"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>RESULT BROADSHEETS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                fab
                    color="red"
                    class="noprint"
                    @click="e1 = 1"
                    >
                    <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn
                    fab
                    color="green"
                    href="javascript:window.print()"
                    class="noprint"
                    >
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header class="noprint">
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                >
                    Broadsheet Type Selection
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                >
                Print BroadSheet
                </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card
                    class="mb-12"
                    color="#fff"
                    >                
                        <!-- Settings Form -->
                        <broadsheet-select-form :authToken="authToken" :classes="classes" :terms="terms" :school="school" @settings="emittedSettings" @subjects="subjectRecords"/>

                        <info-loader v-if="fetching">Kindly be patient! The amount of wait time depends on the total number of student records being fetched &amp; your network strength. </info-loader>
                    </v-card>

                    <v-btn
                    :disabled="!fetchF"
                    color="primary"
                    @click="step2"
                    class="noprint"
                    >
                    Continue
                    </v-btn>

                    <v-btn text @click="reloadPage" class="noprint">
                    Close
                    </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card 
                    v-if="records"
                    class="mb-12"
                    color="#fff"
                    >
                        
                    <!-- Templates -->
                    <broad-sheet-by-subjects v-if="settings.group_by == 'subjects'" :records="records" :school="school" :settings="settings"/>

                    <broad-sheet-by-students v-if="settings.group_by == 'students'" :records="records" :school="school" :settings="settings" :classes="classes" :terms="terms" :subjects="subjects"/>
                    
                    </v-card>

                    <v-btn
                    color="primary"
                    href="javascript:window.print()"
                    class="noprint"
                    >
                    <v-icon>mdi-printer</v-icon>
                    Print
                    </v-btn>

                    <v-btn text @click="e1 = 1" color="red" class="noprint">
                        <v-icon>mdi-chevron-double-left</v-icon>
                        Back
                    </v-btn>
                    <v-btn text @click="reloadPage" class="noprint">
                    Close
                    </v-btn>
                </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import BroadsheetSelectForm from './BroadsheetSelectForm.vue';
import BroadSheetBySubjects from './Templates/BroadSheetBySubjects.vue';
import BroadSheetByStudents from './Templates/BroadSheetByStudents.vue';
import InfoLoader from '../InfoLoader.vue';
export default {
    components: { BroadsheetSelectForm, BroadSheetBySubjects, BroadSheetByStudents, InfoLoader },
    name: 'BroadSheetModal',
    props:[
        'terms', 
        'classes',  
        'gradings', 
        'school',
        'authToken',
        'accessToken'
    ],
    data () {
        return {
            e1: 1,
            fetching:false,
            dialog: true,
            records: null,
            settings: {},
            subjects:null,
            fetchF:false,
            reRenderComponent:true
        }
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    computed:{
        //
    },
    methods: {
        emittedSettings (value){
            this.settings = value
            this.fetchF = true
        },
        subjectRecords (value){
            this.subjects = value
        },
        reloadPage (){
            window.location.reload();
        },
        fetchResults () {
            this.fetching = true
            axios.get(process.env.VUE_APP_BASEURL+'/broadsheets?school_id='+this.school.school_id+'&clasx_id='+this.settings.clasx_id+'&parameter_id='+this.settings.parameter_id+'&group_by='+this.settings.group_by,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.records =  response.data.data                 
                this.e1 = 2       
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                this.fetching = false
            });
        },
        step2 (){
            this.reRenderComponent = false;
            this.$nextTick(() => {
            // Add the component back in
            this.reRenderComponent = true;
            });
            this.fetchResults()
        }
    }
  }
</script>

<style scoped>
</style>
<template>
    <v-row>
        <v-col cols="12" v-if="rsettings && rsettings[0].settings.psymotor">
            <h5 :class="styler">AFFECTIVE SKILLS</h5>
            <table class="table mnr">
            <tbody>
                <tr>
                    <td>PUNCTUALITY</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m1}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>POLITENESS</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m2}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>NEATNESS</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m3}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>HONESTY</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m4}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>LEADERSHIP SKILL</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m5}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>COOPERATION</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m6}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>ATTENTIVENESS</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m7}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>PERSERVERANCE</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m8}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>ATTITUDE TO WORK</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m9}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
            </tbody>
            </table>
            <h5 :class="'mt-2 '+styler">PSYCHOMOTOR SKILLS</h5>
            <table class="table mnr">
            <tbody>
                <tr>
                    <td>HANDWRITING</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m10}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>VERBAL FLUENCY</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m11}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>SPORTS</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m12}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>HANDLING TOOLS</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m13}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
                <tr>
                    <td>DRAWING &amp; PAINTING</td>
                    <td class="text-center" v-if="record.motors">{{record.motors.m14}}</td>
                    <td v-else class="vvalue"></td>
                </tr>
            </tbody>
            </table>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'GeneralMotorSkills',
    props:[
        'record',
        'rsettings',
        'styler'
    ],
}
</script>
<style scoped>
.col-12{
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.vvalue{
    width: 15%;
}
.mnr tbody tr td{
    font-size: 11px !important;
    border: 1px solid #444 !important;
}
</style>
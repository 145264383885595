<template>
    <div v-if="terms && rsettings">
        <span v-if="rsettings[0].settings.ntrd">
            <span v-for="(term,i) in terms.filter((item)=>{return item.parameter_id == rsettings[0].form.parameter_id})" :key="i">
                Resumption: <strong>{{term.resumptiondate}}</strong><br>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'NextTermResumption',
    props:[
        'terms',
        'rsettings',
    ],
}
</script>